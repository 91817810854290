import { SelectChangeEvent } from "../../../../common/ts/events/Events";
import { EventBus } from "../../../../common/ts/events/EventBus";

const bodyElement = document.querySelector('body');
const dropdownElements = document.querySelectorAll('.js-trovit-toggle-dropdown');
const dropdownSelectOptionElements = document.querySelectorAll('.js-trovit-select-dropdown-option');
const eventBus = EventBus.getInstance();
const DROPDOWN_OPTION_SELECTED_EVENT_TYPE = "dropdownOptionSelected";

dropdownElements.forEach((element) => {
    const dropdownElement = element as HTMLSelectElement;

    dropdownElement.addEventListener('click', function (event) {
        event.stopPropagation();
        dropdownElements.forEach((element) => {
            if (element.classList.contains('open') && element !== dropdownElement) {
                element.classList.remove('open');
            }
        });

        dropdownElement.classList.toggle('open');
    });

    if (bodyElement !== null) {
        bodyElement.addEventListener('click', function () {
            if (dropdownElement.classList.contains('open')) {
                dropdownElement.classList.toggle('open');
            }
        });
    }

    dropdownElement.addEventListener(DROPDOWN_OPTION_SELECTED_EVENT_TYPE, function (event) {
        const dropdownOptionSelectedEvent = event as CustomEvent;
        const selectedOption = dropdownElement.querySelector('.option-selected .text') as HTMLElement;
        const textOptionSelected = dropdownOptionSelectedEvent.detail.textOptionSelected;
        const optionSelected = dropdownOptionSelectedEvent.detail.optionSelected;

        if (selectedOption) {
            selectedOption.textContent = textOptionSelected;
        }

        eventBus.emit(new SelectChangeEvent({
            id: dropdownElement.id,
            name: textOptionSelected,
            value: optionSelected
        }));
    });
});

dropdownSelectOptionElements.forEach((element) => {
    const dropdownSelectOptionElement = element as HTMLSelectElement;

    dropdownSelectOptionElement.addEventListener('click', function (event) {
        const optionSelected = dropdownSelectOptionElement.getAttribute("data-option");
        const textOptionSelected = dropdownSelectOptionElement.textContent;

        dropdownSelectOptionElements.forEach((el) => {
            el.classList.remove('selected');
        });
        dropdownSelectOptionElement.classList.add('selected');

        const dropdownOptionSelectedEvent = new CustomEvent(
            DROPDOWN_OPTION_SELECTED_EVENT_TYPE, {
                detail: {
                    textOptionSelected: textOptionSelected,
                    optionSelected: optionSelected
                }, bubbles: true
            });
        dropdownSelectOptionElement.dispatchEvent(dropdownOptionSelectedEvent);
    });
});